.error-page-bg {
    background-color: #000;
    height: 100%;
    width: 100%;
    background: linear-gradient(170deg, rgba(0, 0, 0, 1) 0%, rgba(38, 170, 225, 0.9) 150%) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-title-error {
    font-size: 2rem;
}

.error-message{
    font-size: 1.1rem;
    text-align: center;
}

.btn-sw-cta-return {
    width: 25%;
    text-transform: uppercase;
    padding: .75rem 1.5rem;
    background-image: linear-gradient(-180deg, #26aae1 0%, #0972b9 100%);
    border: none;
    color: white;
    transition: 0.5s all;

    &:hover {
        color: white;
        transform: translateY(3px);
        background-color: rgba(46, 185, 255, 0.9);
    }

    &:active {
        color: white;
        background-color: rgba(46, 185, 255, 0.8);
    }
}

.return-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

