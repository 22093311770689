html,
body {
  font-family: "Montserrat", Times, serif;
  background-color: #020202;
  color: white;
  // overflow-y:auto;

  // overflow: scroll;
  // overflow-x: hidden;

  .kt-scrolltop {
    background-color: #26aae1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(38, 170, 225, 0.5);
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(38, 170, 225, 0.1);
    border-radius: 4px;
  }
}

#splash-screen {
  background-color: #020202;
}

.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
  overflow-y: scroll;
}

.kt-portlet {
  margin: 50px 0;
}

button,
.btn {
  font-weight: 500;
}

// Login
.kt-login.kt-login--v4 {
  position: relative;
  overflow-y: hidden !important;

  .btn-sw-cta-sign-in {
    width: 100%;
    text-transform: uppercase;
    padding: 1rem 1.5rem;
    color: white;
    // background-color: rgba(38, 170, 225, 0.8);
    background-image: linear-gradient(-180deg, #26aae1 0%, #0972b9 100%);
    border: none;

    &:hover {
      transform: scale(1.02);
      background-color: rgba(38, 170, 225, 0.9);
    }

    &:active {
      background-color: rgba(38, 170, 225, 0.8);
    }
  }
}

.kt-login.kt-login--v4 .kt-login__wrapper .kt-login__container {
  width: 330px;
  background-color: rgba(32, 32, 32, 0.94);
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  padding: 4rem 2rem;
  border-radius: 8px;
}

.kt-login.kt-login--v4 .kt-login__wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  padding-bottom: 6%;
}

.kt-login.kt-login--v4 .kt-login__wrapper .kt-login__container .kt-login__logo {
  margin-bottom: 3rem;
}

.kt-login.kt-login--v4
  .kt-login__wrapper
  .kt-login__container
  .kt-login__head
  .kt-login__title {
  color: white;
  font-size: 1.7rem;
  letter-spacing: 0.2rem;
}

.kt-login.kt-login--v4
  .kt-login__wrapper
  .kt-login__container
  .kt-form
  .form-control {
  border: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 0.25rem;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.05);

  &:focus {
    border-left: 1px solid #26aae1;
    color: white;

    &::placeholder {
      color: rgba(255, 255, 255, 1);
    }
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

.kt-login.kt-login--v4
  .kt-login__wrapper
  .kt-login__container
  .kt-form
  .kt-login__extra
  .kt-login__link,
.back-btn {
  color: rgba(255, 255, 255, 0.7˜);
  border-bottom: 1px solid transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font-weight: lighter;
  background-color: transparent;

  &:hover {
    color: hsla(0, 0%, 100%, 0.5);
    border-bottom: 1px dotted #26aae1;
  }
}

.back-lbl {
  transition: 0.3s all;
}

.kt-login.kt-login--v4
  .kt-login__wrapper
  .kt-login__container
  .kt-form
  .kt-login__extra {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  margin-top: 7%;
}

.kt-login {
  label {
    cursor: pointer;

    &.kt-fake__border {
      border: 1px solid transparent;
    }
  }

  .password-type {
    // text-security: disc !important;
    -webkit-text-security: disc !important;
    -moz-text-security: disc !important;
    -ms-text-security: disc;
  }
}

.btn-danger-border {
  background-color: transparent;
  border: 1px solid #942911;

  &:hover {
    background: linear-gradient(to bottom, #d0451b 0%, #982907 100%);
    background-color: #b30000;
    transform: scale(1.02);
    border: 1px solid #942911;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    -moz-backface-visibility: hidden !important;
    -ms-backface-visibility: hidden !important;
    transition: 0.5s all !important;
  }

  &:focus,
  &:active {
    background: linear-gradient(to bottom, #d0451b 0%, #982907 100%) !important;
    background-color: #b30000 !important;
    border: 1px solid #942911 !important;
  }
}

.alert.alert-danger {
  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #b30000 5%, #bc3315 100%);
  background-color: #b30000;
  border: 1px solid #942911;

  &:hover {
    background: linear-gradient(to bottom, #bc3315 5%, #b30000 100%);
    background-color: #bc3315;
  }
}

.alert.alert-warning {
  color: #ffb822;
  background-color: transparent;
  border: 1px solid #ffb822;
}

// Buttons
.btn-sw-cta {
  color: white;
  background-color: rgba(38, 170, 225, 0.8);
  border: none;

  &:hover {
    color: white;
    background-color: rgba(38, 170, 225, 1);
  }

  &:active {
    background-color: rgba(38, 170, 225, 0.8);
  }
}

.btn-sw-primary {
  color: white;
  background-color: rgba(38, 170, 225, 0.4);
  border: none;

  &:hover {
    color: white;
    background-color: rgba(38, 170, 225, 0.7);
  }

  &:active {
    background-color: rgba(38, 170, 225, 0.4);
  }
}

.btn-sw-outlined {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border: 1px solid rgba(38, 170, 225, 0.4);

  &:hover {
    background-color: rgba(38, 170, 225, 0.4);
    color: white;
  }
}

.btn-sw-simple {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border: none;

  &.btn-refresh {
    position: absolute;
    top: -1px;
    transform: scale(0.9);
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &:active {
    color: rgba(255, 255, 255, 0.5);
  }
}

.btn-brand {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-color: hsla(0, 0%, 100%, 0);

  &:hover,
  &:focus {
    background-color: rgba(38, 170, 225, 0.7);
    border-color: rgba(38, 170, 225, 0);
    color: #fff;
  }
}

.cancel-btn-dialog {
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  color: rgba(255, 255, 255, 0.8) !important;
  transition: 0.5s all;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: rgba(32, 32, 32, 0.8) !important;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
  }
}

.cta-btn-dialog {
  background-color: #26aae1 !important;
  color: #fafafa !important;
  border: none;

  // &:hover {
  //     background-color: rgba(38, 170, 225, .4)!important;
  // }

  &:active {
    background-color: #26aae1 !important;
    border: 1px solid #26aae1 !important;
  }
}

.btn-danger {
  background: linear-gradient(to bottom, #d0451b 0%, #982907 100%) !important;
  background-color: #982907 !important;
  border: 1px solid #982907 !important;
  color: white !important;

  &:hover {
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    -moz-backface-visibility: hidden !important;
    -ms-backface-visibility: hidden !important;
    transition: 0.5s all !important;
    transform: scale(1.02) !important;
    border: 1px solid #982907 !important;
  }

  &:focus,
  &:active {
    border: 1px solid #982907 !important;
  }
}

.MuiButton-root {
  font-size: 1rem;
}

.MuiDialogActions-root {
  padding: 10px;
}

.MuiButton-label {
  text-transform: none;
  font-weight: normal;
  font-size: 1rem;
}

.MuiFormLabel-root.Mui-focused {
  color: #26aae1 !important;
}

.generate-access-key {
  .MuiInput-underline {
    &:hover:not(.Mui-disabled) {
      &::before {
        border-bottom: 1px solid #26aae1 !important;
      }
    }

    &:before {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }

    &::after {
      border-bottom: 2px solid #26aae1 !important;
    }

    .MuiInputBase-input {
      cursor: pointer;
    }
  }
}

.access-key-field,
.uuid {
  .MuiInput-underline {
    &:hover:not(.Mui-disabled) {
      &::before {
        border-bottom: 1px solid transparent !important;
      }
    }

    &::before {
      border-color: transparent !important;
    }

    &::after {
      border-bottom: 1px solid #26aae1 !important;
    }
  }

  .MuiInputBase-input {
    cursor: pointer;
  }
}

// Header
.kt-header {
  background-color: transparent;
}

.header-progress-bar {
  display: none !important;

  .progress-bar {
    background-color: #26aae1;
  }
}

.kt-header-mobile--fixed .kt-header-mobile {
  background-color: #111;
}

.kt-header__brand-logo-sticky {
  height: 20px;
}

.kt-header__brand-logo-default {
  height: 45px;
}

// .kt-header--fixed.kt-header--minimize {
//     .kt-menu__link-text {
//         color: #020202 !important;
//     }
// }

.kt-radio > span:after {
  // border: solid #bfc7d7;
  // background: #bfc7d7;
  border: rgba(38, 170, 225, 0.8);
  background: rgba(38, 170, 225, 1);
}

// Sidebar Mobile
#sidebar {
  display: none;
}

.kt-header-menu-mobile {
  background: transparent;
}

.kt-header-menu-wrapper {
  background: linear-gradient(0deg, #254858 0%, rgba(32, 32, 32, 0.94) 100%);
}

.kt-header-menu {
  .kt-header-menu-mobile {
    background: transparent;
  }

  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background: transparent;
  }
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-text {
  color: white;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #26aae1;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: white;
}

.kt-header-mobile
  .kt-header-mobile__toolbar
  .kt-header-mobile__toolbar-toggler
  span {
  background: white;

  &::before,
  &::after {
    background: white;
  }
}

.kt-header-mobile
  .kt-header-mobile__toolbar
  .kt-header-mobile__toolbar-topbar-toggler
  i {
  color: white;
}

// Portlet
.kt-portlet {
  background-color: rgba(32, 32, 32, 0.94);
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
}

.kt-portlet.kt-portlet--border-bottom-brand {
  border-bottom: none;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  color: #26aae1;
  font-size: 1.7rem;

  small {
    color: hsla(0, 0%, 100%, 0.5);
  }
}

.kt-portlet .kt-portlet__head {
  background: linear-gradient(
    270deg,
    rgba(250, 250, 250, 0.1) 0%,
    rgba(32, 32, 32, 0.94) 95%
  );
  border-bottom: 1px solid #020202;
}

.kt-section .kt-section__title {
  color: white;
}

.kt-avatar .kt-avatar__holder {
  border-radius: 8px;
}

.kt-link {
  color: #26aae1;

  &:hover {
    color: hsla(0, 0%, 100%, 0.5);

    &::after {
      border-bottom: 1px solid #26aae1;
    }
  }
}

.kt-avatar.kt-avatar--outline .kt-avatar__holder {
  border: none;
}

.kt-avatar .kt-avatar__upload {
  i {
    color: rgba(38, 170, 225, 0.7);
  }

  &:hover {
    background-color: rgba(38, 170, 225, 0.7);
  }
}

// Form
.form-control[readonly] {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 1);
  cursor: not-allowed;

  &:focus {
    border-color: none;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  color: rgba(255, 255, 255, 0.5);
}

.form-control {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: white;

  &:focus {
    background-color: transparent;
    border-color: rgba(38, 170, 225, 1);
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.kt-widget11 .table thead > tr > td {
  padding-bottom: 7px;
  padding-left: 2rem;
}

// .kt-widget11 .table thead>tr>td:last-child {
//     text-align: center;
// }

.kt-widget11 .table tbody > tr {
  border-bottom: none;
}

.kt-widget11 .table tbody > tr > td {
  padding-top: 13px;
  padding-bottom: 13px;
  overflow-x: scroll;
  padding-left: 2rem;
}

.kt-widget11 .table thead > tr > td:first-child {
  padding-left: 25px;
}

.kt-widget11 .table tbody > tr > td:first-child {
  padding-left: 25px;
}

// .kt-widget11 .table tbody>tr>td:last-child {
//     // padding-right: 15px;
//     text-align: center;
// }

.kt-subheader {
  padding: 10px 0 20px 0 !important;
  height: auto;
}

.kt-widget11 .table thead > tr > td {
  color: white;
}

.kt-widget11 .table tbody > tr .kt-widget11__title,
.kt-widget11__sub {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: normal;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}

.kt-checkbox > span {
  border: 1px solid rgba(255, 255, 255, 0.7);

  &:hover {
    border: 1px solid white;
  }
}

.kt-checkbox > span {
  &::after {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.kt-checkbox > input:checked ~ span {
  border: 1px solid rgba(255, 255, 255, 0.5);

  &:hover {
    border: 1px solid white;

    &::after {
      border: 1px solid rgba(255, 255, 255, 1);
    }
  }
}

.kt-widget11 .table-credentials tbody > tr > td > label,
.kt-widget11 .table-buckets tbody > tr > td > label {
  top: 0;
}

.bucket-name {
  display: inline-block !important;
  text-decoration: underline !important;

  &:hover {
    color: white !important;
    text-decoration: underline !important;
  }
}

.remove-icon {
  color: rgba(255, 255, 255, 0.5);

  &:hover {
    color: white;
  }
}

.kt-widget11 .table tbody > tr > td {
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
}

//Dialog box

.MuiDialog-paperWidthSm {
  width: 600px;
}

.MuiPaper-root {
  animation: fadeIn 0.7s linear;
  background-color: rgb(32, 32, 32) !important;
  color: rgba(255, 255, 255, 0.94) !important;
}

.MuiDialog-root {
  background-color: rgba(0, 0, 0, 0.5);

  .MuiDialogTitle-root {
    border-left: 4px solid #26aae1;
    // background: linear-gradient(90deg, rgba(0, 0, 0, 0.2)10%, #fafafa  100%);
    background: linear-gradient(
      270deg,
      rgba(250, 250, 250, 0.1) 0%,
      rgba(32, 32, 32, 0.94) 95%
    );
    padding-left: 20px;
  }

  .MuiDialogContent-root {
    .kt-checkbox > span {
      border: 1px solid rgba(255, 255, 255, 0.8);
    }

    .kt-checkbox > span {
      &::after {
        border: 1px solid rgba(255, 255, 255, 0.8);
      }
    }

    .kt-checkbox > input:checked ~ span {
      border: 1px solid rgba(255, 255, 255, 0.8);

      &::after {
        border: 1px solid rgba(38, 170, 225, 1);
      }
    }
  }
}

.kt-checkbox {
  margin-bottom: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//User card

.dropdown-menu {
  background-color: transparent;
  padding: 0;

  ul {
    list-style: none;
    width: 100%;
  }

  .kt-user-card .kt-user-card__badge {
    padding: 0;
    text-align: center;
  }
}

.topbar-profile-item {
  padding: 0.75rem 1.5rem;
  color: white;
  border-radius: 4px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.kt-user-card {
  // background-color: rgba(255,255,255,0.2);
  background-color: rgb(63, 82, 94);
  padding: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: 15px;

  .kt-notification__custom {
    padding: 0.75rem 1.5rem;
  }
}

.btn.btn-label-brand {
  background-color: rgba(38, 170, 225, 0.4);
  font-size: 15px;
  font-weight: lighter;

  &:hover {
    background-color: rgba(38, 170, 225, 0.7) !important;
  }
}

//Footer
.kt-footer {
  background-color: transparent;

  .kt-footer__bottom .kt-footer__wrapper {
    justify-content: flex-end;
  }

  .kt-footer-logo {
    height: 15px;
  }
}

.kt-footer .kt-footer__bottom .kt-footer__logo .kt-footer__copyright {
  color: rgba(255, 255, 255, 0.5);
}

// Custom
.button-row,
.delete-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(51, 51, 51, 0.9) 0%,
    rgba(32, 32, 32, 0.6) 95%
  );
  border-radius: 4px;
  border-left: 2px solid #26aae1;
}

.delete-row {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
}

.download-text {
  line-height: 1.7em;
}

.spinner {
  .path {
    stroke: #26aae1;
  }
}

//Mobile
.kt-header-mobile__logo {
  img {
    height: 45px;
  }
}

// .kt-header-mobile{
//     background-color: transparent;
// }

#root {
  flex-direction: row;
  //   background-color: #111;
  background: linear-gradient(
    180deg,
    rgba(30, 112, 154, 0.5) 0%,
    rgba(2, 2, 2, 0.1) 100%
  );
}

.rccs__card--unknown > div {
  background-image: linear-gradient(90deg, #26aae1 0%, #0972b9 100%) !important;
}

//Hide arrows input number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-white {
  color: rgba(255, 255, 255, 1);
}

.text-gray {
  color: rgba(255, 255, 255, 0.5);
}

.text-blue {
  color: #26aae1;
}

// #credit-card-show{
//     max-width: 800px;
//     max-height: 300px;
//     overflow-y: hidden;

//     /*Transition time is increased to accomodate the height */
//     transition: max-height 0.7s linear;
// }

// #credit-card-hide{
//     max-width: 800px;
//     max-height: 0;
//     overflow-y: hidden;

//     /*Transition time is increased to accomodate the height */
//     transition: max-height 0.7s linear;
// }

.ant-picker-input > input {
  cursor: pointer;
}

.month-picker {
  position: absolute;
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.ant-picker-panel-container {
  background-color: #363738;
}

// .ant-picker-dropdown-placement-bottomRight {

// }

.ant-picker-year-btn,
.ant-picker-header button {
  color: rgba(255, 255, 255, 0.7);

  &:hover {
    color: white;
  }
}

.ant-picker-panel {
  border: none;
  border-radius: 4px;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background-color: #26aae1;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 4px;
}

.ant-picker-cell-in-view {
  color: rgba(255, 255, 255, 0.7);
}

li {
  list-style-type: none;
}

.alias-span {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
}

.name-address-span,
.credit-card-selected-span {
  font-size: 1.1rem;
  color: white;
  font-weight: normal;
}

.credit-card-span,
.free-credits {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
}

.billing-address-span,
.code-city-span,
.referrals-info {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: lighter;
}

tbody {
  &.striped {
    tr:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }
}

select {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: rgba(38, 170, 225, 1);
    outline: none;
  }
}

.referral-code {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
}

.referral-link {
  font-size: 1.1rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.5);
}

.get-credit {
  font-size: 1.4rem;
  color: white;
  font-weight: lighter;
}

.contact-referral {
  background-color: transparent;
  border: none;
  color: #26aae1;
  padding: 0.65rem 10px;
  font-weight: normal;

  &:hover {
    color: #26aae1;
  }
}

h6,
h2 {
  color: white;
}

.MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.5) !important;
}

.MuiInputBase-input {
  color: white;
}

.MuiInputBase-root {
  color: rgba(255, 255, 255, 0.5) !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.plus-button-gray-border {
  padding: 0 !important;
  min-width: 45px !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-light-dark-225 {
  width: 100%;
  height: 225px;
  border-radius: 4px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 1.75rem;
  transition: 0.5s all;
  display: inline-block;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.4);
}

.bucket-justify-content {
  justify-content: space-between;
}

//checkbox to toggle button
.cm-toggle-enabled,
.cm-toggle-disabled {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin: 10px;
}

/* To create surface of toggle button */
.cm-toggle-enabled:after,
.cm-toggle-disabled:after {
  content: "";
  width: 55px;
  height: 18px;
  display: inline-block;
  background: rgba(196, 195, 195, 0.55);
  border-radius: 30px;
  clear: both;
}

/* Contents before checkbox to create toggle handle */
.cm-toggle-enabled:before,
.cm-toggle-disabled:before {
  content: "";
  height: 18px;
  width: 30px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

/* Shift the handle to left on check event */
.cm-toggle-enabled:before {
  left: 32px;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.7);
}

/* Background color when toggle button will be active */
.cm-toggle-enabled:after {
  background: #26aae1;
}

.cm-toggle-enabled,
.cm-toggle-disabled,
.cm-toggle-enabled:before,
.cm-toggle-disabled:before,
.cm-toggle-enabled:after,
.cm-toggle-disabled:after,
.cm-toggle-enabled:checked:before,
.cm-toggle-disabled:checked:before,
.cm-toggle-enabled:checked:after,
.cm-toggle-disabled:checked:after {
  transition: ease 0.3s;
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  -o-transition: ease 0.3s;
}

.checkbox-text-enabled,
.checkbox-text-disabled {
  margin-left: 0.5rem;
}

.checkbox-text-enabled {
  color: white;
}

.checkbox-text-disabled {
  color: rgba(255, 255, 255, 0.5);
}

.bucket-tbody {
  display: block;
  height: 295px;
  overflow: auto;
}

.buckets-tbody,
.credentials-tbody {
  display: block;
  height: 448px;
  overflow: auto;
}

.table-credentials > thead > tr > td:first-child {
  width: 6%;
  overflow-x: hidden;
}

.table-credentials > thead > tr > td:nth-child(2) {
  width: 46%;
}

.table-buckets > thead > tr > td:first-child {
  width: 6%;
  overflow-x: hidden !important;
}

.table-buckets > thead > tr > td:nth-child(2) {
  width: 22%;
}

.table-buckets > thead > tr > td:nth-child(3) {
  width: 22%;
}

.table-buckets > thead > tr > td:nth-child(5) {
  width: 10.5%;
}

.table-buckets > thead > tr > td:last-child {
  width: 10%;
}

.credentials-tbody > tr > td:first-child {
  overflow-x: hidden !important;
  width: 6%;
}

.credentials-tbody > tr > td:nth-child(2) {
  width: 46.5%;
  padding-top: 8px !important;
  padding-bottom: 7px !important;
}

.credentials-tbody > tr > td:last-child {
  width: 10%;
}

.buckets-tbody > tr > td:first-child {
  width: 6%;
  overflow-x: hidden !important;
}

.buckets-tbody > tr > td:nth-child(2) {
  width: 22%;
}

.buckets-tbody > tr > td:nth-child(3) {
  width: 22%;
}

.buckets-tbody > tr > td:nth-child(5) {
  width: 10%;
  overflow-x: hidden !important;
}

.buckets-tbody > tr > td:last-child {
  width: 10%;
}

.alerts-tbody {
  display: block;
  height: 350px;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.search-form {
  display: flex;
  width: 100%;
}

.form-search {
  width: 88%;
}

.bucket-link-white {
  color: rgba(255, 255, 255, 1) !important;
}

.table-bucket > thead > tr > td:first-child {
  width: 40%;
}

.table-bucket > thead > tr > td:nth-child(2) {
  width: 45.5%;
}

.bucket-tbody > tr > td:first-child {
  width: 40.5%;
}

.bucket-tbody > tr > td:nth-child(2) {
  width: 45.5%;
}

.bucket-title {
  color: #26aae1;
}

.MuiInputBase-input {
  color: white;
}

#bucket-creation {
  .MuiInput-underline {
    &:hover:not(.Mui-disabled) {
      &::before {
        border-bottom: 1px solid #26aae1 !important;
      }
    }

    &:before {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }

    &::after {
      border-bottom: 2px solid #26aae1 !important;
    }
  }
}

.kt-widget11 .table tbody > .deletingbucket {
  display: none !important;
}

.bucket-error-message {
  color: #bc3315;
}

.container-bucket-uuid {
  justify-content: space-between;
}

.share-btn {
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 1.5rem;
  color: white;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-backface-visibility: hidden;

  &:hover {
    transition: all 0.5s;
    transform: scale(1.05);
  }
}

.info-button {
  &:hover {
    .info-text-uuid {
      position: absolute;
      display: block;
      background-color: #323232;
      border: 1px solid #494949;
      color: white;
      font-size: 14px;
      font-weight: lighter;
      padding: 1.5rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 100000;
      width: 250px;
      left: -220px;
      top: 70px;
      pointer-events: none;
      border-radius: 4px;
    }
  }
}

.info-text-uuid {
  display: none;
}

.uuid-field {
  min-width: 350px;
}

@media (min-width: 768px) {
  .info-button {
    &:hover {
      .info-text-uuid {
        right: 0;
        top: 40px;
      }
    }
  }
}
