// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

//Aside Styles
@import "./app/assets/sass/aside.scss";

// Custom Styles
@import "./app/assets/sass/custom.scss";

// Responsive Styles
@import "./app/assets/sass/responsive.scss";

// Error Styles
@import "./app/assets/sass/errors.scss";

//Credit Card Styles
@import "../node_modules/react-credit-cards/lib/styles.scss";

//Date Picker Styles
@import "../node_modules/antd/dist/antd.css";

//Dashboard Styles
@import "./app/assets/sass/dashboard.scss";

//Loading Styles
@import "./app/assets/sass/loading.scss";

//Hosts Map Styles
@import "./app/assets/sass/hostsMap.scss";

//Horizontal Tabs used in Bucket
@import "./app/assets/sass/horizontalTabs.scss";
