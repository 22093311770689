//Graph Loader
$note-width: 14px;
$note-margin: 3px;

$loader-padding: 32px;
$loader-height: 75px;

$notes: 5;

$anim-delay: 0.35s;
$anim-duration: 2s;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  background: transparent;
  position: relative;
  height: $loader-height;
  width: (
    $notes * ($note-width + $note-margin) + ($loader-padding * 2) - $note-margin
  );
  overflow: hidden;
  box-shadow: -1px 12px 14px -15px #0000002b;

  &:after {
    content: "";
    width: 100%;
    height: $loader-height * 0.77;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
  }
}

.status {
  color: rgba(255, 255, 255, 0.5);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 1rem;
}

@for $i from 0 to $notes {
  .note-#{$i} {
    position: absolute;
    background: linear-gradient(180deg, #26aae1 0%, #2d5264 100%);
    width: $note-width;
    left: $i * ($note-width + $note-margin) + $loader-padding;
    height: $loader-height;
    top: 100%;
    animation: slide $anim-duration infinite $i * $anim-delay;
    animation-timing-function: ease-out;
    border-radius: 12px;
  }
}

@keyframes slide {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

//Data loader
$spCol: #26aae1;
.sp-3balls,
.sp-3balls:before,
.sp-3balls:after {
  border-radius: 50%;
  background-color: $spCol;
  width: 14px;
  height: 14px;
  transform-origin: center center;
  display: inline-block;
}
.sp-3balls {
  position: relative;
  background-color: rgba($spCol, 1);
  opacity: 1;
  -webkit-animation: spScaleAlpha 1s infinite linear;
  animation: spScaleAlpha 1s infinite linear;
}
.sp-3balls:before,
.sp-3balls:after {
  content: "";
  position: relative;
  opacity: 0.25;
}
.sp-3balls:before {
  left: 30px;
  top: -1px;
  -webkit-animation: spScaleAlphaBefore 1s infinite linear;
  animation: spScaleAlphaBefore 1s infinite linear;
}
.sp-3balls:after {
  left: -30px;
  top: -22px;
  -webkit-animation: spScaleAlphaAfter 1s infinite linear;
  animation: spScaleAlphaAfter 1s infinite linear;
}
@-webkit-keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@-webkit-keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
