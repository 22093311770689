

.tab-content {
    background-color: transparent;
}

.tab-billing {
    margin: 0;

    &.nav-link {
        border-radius: 4px;
        width: 33.33%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        padding: 1rem 0.5rem;
        font-weight: normal;
    }

    &.nav-link.active {
        color: white;
        background-color: rgba(48, 170, 225, 0.2);
        border: none;
    }
}

.nav-pills .nav-item {
    margin-right: 0;
    background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
    color: white;
    border-bottom: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
}

.tab-bucket {
    margin: 0;

    &.nav-link {
        border-radius: 4px;
        width: 50%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        padding: 1rem 0.5rem;
        font-weight: normal;
    }

    &.nav-link.active {
        color: white;
        background-color: rgba(48, 170, 225, 0.2);
        border: none;
    }
}