//Hosts Map
#map {
  background-color: transparent !important;
  padding: 0 !important;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-map {
  border-radius: 4px;
}

.kt-portlet.map {
  height: 250px;
  position: relative;
  border: 1px solid #313131;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-logo {
  transform: scale(0.7);
  margin-bottom: -10px !important;
  margin-left: -7px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: transparent !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  &:after {
    width: 20px !important;
    height: 20px !important;
    background-color: transparent !important;
    bottom: -5px !important;
  }
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  &:hover {
    padding: 2px 22px 0px 4px !important;
    margin-top: 0px !important;
  }
}

.mapboxgl-ctrl-attrib a {
  color: #aaa !important;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  //   height: 500px!important;
  width: 100%;
  padding: 50px;
  background-color: #232323;
}

.section-map {
  padding-bottom: 300px;
}

.map-title {
  color: #999;
  font-size: 14px;
  font-weight: normal;
}

.mapboxgl-popup {
  top: 7px !important;
  left: -1px !important;
  z-index: 3;
}

.mapboxgl-popup-mds {
  top: 8px !important;
  left: 0px !important;
  z-index: 2;
}

.mapboxgl-popup-gateway {
  top: 8px !important;
  left: 0px !important;
  z-index: 2;
}

.mapboxgl-popup-content {
  border-radius: 4px !important;
  // padding: 20px!important;
  padding: 0px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.popup-title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(135, 127, 59, 1);
  color: white;
  width: 100%;
  padding: 15px;
  padding-right: 18px !important;
  font-size: 16px;
}

.popup-title-mds {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(116, 90, 206, 1);
  color: white;
  width: 100%;
  padding: 15px;
  padding-right: 18px !important;
  font-size: 16px;
}

.popup-title-gateway {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(5, 149, 64, 1);
  color: white;
  width: 100%;
  padding: 15px;
  padding-right: 18px !important;
  font-size: 16px;
}

.popup-subtitle {
  background-color: rgba(135, 127, 59, 0.8);
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 15px;
}

.popup-subtitle-mds {
  background-color: rgba(116, 90, 206, 0.8);
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 15px;
}

.popup-subtitle-gateway {
  background-color: rgba(5, 149, 64, 0.8);
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 15px;
}

.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-tip {
  border-width: 8px !important;
}

.mapboxgl-popup-close-button {
  color: white !important;
}

.circle-wrapper {
  position: relative;
  .outer-circle {
    z-index: 2;
    position: absolute;
    background-color: #f1c40f;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  .inner-circle {
    position: absolute;
    background-color: #f1c40f;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    left: 2px;
    top: 2px;
    z-index: 3;
  }
  .outer-circle-mds {
    background-color: #8161e5;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    z-index: 2;
    position: absolute;
  }
  .inner-circle-mds {
    position: absolute;
    background-color: #a184fb;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    left: 2px;
    top: 2px;
    z-index: 3;
  }
  .outer-circle-gateway {
    position: absolute;
    background-color: rgb(5, 149, 64);
    border-radius: 50%;
    width: 6px;
    height: 6px;
    left: 2px;
    top: 2px;
    z-index: 3;
  }

  .inner-circle-gateway {
    background-color: rgb(9, 184, 81);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    z-index: 2;
    position: absolute;
  }
}

.mds-marker,
.gateway-marker {
  z-index: 1;
}

// .pulse-host {
//      box-shadow: 0 0 0 0 rgba(105, 78, 112, .7), 80px -100px 0 -80px #26aae1;
//      background-color: #26aae1;
//      box-shadow: 0 0 0 0 rgba(105, 78, 112, .7), 150px -150px 0 -80px #26aae1;
//      animation: 1.75s cubic-bezier(.66, 0, 0, 1) infinite pulse;
//      -webkit-animation: 1.75s cubic-bezier(.66, 0, 0, 1) infinite pulse;
//      border-radius: 50%;
//      background: #26aae1;
//      // position: absolute;
// }

// @-webkit-keyframes pulse {
//      0% {
//      -webkit-box-shadow: 0 0 0 0 rgba(38, 170, 225, 0.4);
//      }

//      70% {
//      -webkit-box-shadow: 0 0 0 35px rgba(38, 170, 225, 0);
//      }

//      100% {
//      -webkit-box-shadow: 0 0 0 0 rgba(38, 170, 225, 0);
//      }
// }

// @keyframes pulse {
//      0% {
//      -moz-box-shadow: 0 0 0 0 rgba(38, 170, 225, 0.4);
//      box-shadow: 0 0 0 0 rgba(38, 170, 225, 0.4);
//      }

//      70% {
//      -moz-box-shadow: 0 0 0 35px rgba(38, 170, 225, 0);
//      box-shadow: 0 0 0 35px rgba(38, 170, 225, 0);
//      }

//      100% {
//      -moz-box-shadow: 0 0 0 0 rgba(38, 170, 225, 0);
//      box-shadow: 0 0 0 0 rgba(38, 170, 225, 0);
//      }
// }
