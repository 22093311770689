$dashboard-big-card-height: 460px;
$dashboard-small-card-height: $dashboard-big-card-height/2;

.card-blue {
  width: 100%;
  height: 225px;
  border-radius: 4px;
  background-color: rgba(48, 170, 225, 0.2);
  border: 1px solid #2d5264;
  margin-top: 1.75rem;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }
}

.card-dark-225 {
  width: 100%;
  height: 225px;
  border-radius: 4px;
  border: 1px solid #313131;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }
}

.card-dark {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #313131;
  border-top: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  transition: 0.5s all;
  display: inline-block;
}

.card-dark-475 {
  width: 100%;
  height: 475px;
  border-radius: 4px;
  border: 1px solid #313131;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }

  .mb-175 {
    margin-bottom: 1.75rem;
  }
}

.card-dark-460 {
  width: 100%;
  height: $dashboard-big-card-height;
  border-radius: 4px;
  border: 1px solid #313131;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  // border: 1px solid #dee2e6 !important;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }
}

.card-dark-280 {
  width: 100%;
  height: 280px;
  border-radius: 4px;
  border: 1px solid #313131;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  // border: 1px solid #dee2e6 !important;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }
}

.card-medium-dark {
  width: 100%;
  height: $dashboard-big-card-height/1.5;
  border-radius: 4px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-top: 1.75rem;
  // border: 1px solid #dee2e6 !important;
  transition: 0.5s all;
  display: inline-block;

  // &:hover {
  //     transform: scale(1.02);
  // }
}

.wrapper-data-insight {
  margin-top: 1.75rem;
}

.data-insight-row {
  background: linear-gradient(
    270deg,
    rgba(50, 50, 50, 1) 0%,
    rgba(32, 32, 32, 0.94) 95%
  );
  border: 1px solid #313131;
  border-bottom: none;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 4px;
  justify-content: space-between;
}

.btl-radius-0 {
  border-top-left-radius: 0 !important;
}

.btr-radius-0 {
  border-top-right-radius: 0 !important;
}

.card-title-chart {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  margin-bottom: 0;
  margin: 3rem 0 0 0;
  font-weight: 600;
  border-radius: 4px;
  font-size: 1.3rem;
}

// .first-byte-wrapper {
//     margin-top: -1rem;
// }

.chart-wrapper {
  height: 200px;
}

.chart-tier-wrapper {
  height: 470px;
}

.chart-bg {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
}

.card-title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  font-size: 1.3rem;
}

.traffic-type {
  color: rgba(48, 170, 225, 0.5);
  font-weight: 700;
  font-size: 1.1rem;
}

.traffic-value {
  font-weight: 500;
  font-size: 1.8rem;
  color: #26aae1;
}

.card-title-small {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.1rem;
  font-weight: 500;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.dashboard-value-size-1 {
  font-size: 2.1rem;
  color: rgba(255, 255, 255, 1);
}

.dashboard-value-size-2 {
  font-size: 1.8rem !important;
}

.font-size-1-1 {
  font-size: 1.1rem !important;
}

.unit {
  color: rgba(255, 255, 255, 0.4);
}

.wrapper-total {
  height: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: space-around;
}

// .border-neon {
// -webkit-animation: glow 800ms ease-out infinite alternate;
// animation: glow 800ms ease-out infinite alternate;
// background-color: rgba(28, 28, 28, 0.94);
// box-shadow: 0 0 5px rgba(48, 170, 225, 0.2), inset 0 0 5px rgba(48, 170, 225, 0.2), 0 2px 0 #000;
// border-color: rgba(48, 170, 225, 0.2);
// border: 1px solid rgba(48, 170, 225, .5);
// box-shadow: 0 0 20px rgba(48, 170, 225, 0.8), inset 0 0 10px rgba(48, 170, 225, 0.5), 0 2px 0 #000;

// outline: none;
// }

.stats-number-row {
  justify-content: space-between;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(28, 28, 28, 0.94)),
    to(#232323)
  );
  border: 1px solid #313131;
  background: linear-gradient(rgba(28, 28, 28, 0.94), #232323);
  margin-left: 0;
  margin-right: 0;
  padding: 1.75rem;
  border-radius: 4px;
  margin-top: 1.75rem;

  .stats-number-col {
    padding-right: 10px;
    padding-left: 10px;
    position: relative;
    display: flex;
    flex-direction: column;

    span {
      color: rgba(255, 255, 255, 0.5);
    }

    .main-text {
      color: #26aae1;
    }

    &.bordered {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -75px;
        height: 100%;
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
      }
    }
  }
}

text {
  font-family: "Montserrat", Times, serif !important;
  fill: rgba(255, 255, 255, 0.7) !important;
  font-weight: 500 !important;
}

.semicircle-container {
  text-align: center;
  transform: scale(1.3);
}

.container-5 {
  display: flex;
  flex-wrap: wrap;
  // justify-content:space-around;

  .col-20 {
    flex: 0 0 20%;
    max-width: 20%;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-40 {
    flex: 0 0 40%;
    max-width: 40%;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.stats-summary {
  color: #26aae1;
  font-size: 1.1rem;
}

.traffic-wrapper {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.title-dashboard {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
}

.text-gray {
  color: rgba(255, 255, 255, 0.5);
}

.value-dashboard {
  color: rgba(255, 255, 255, 1);
}

.wrapper-op {
  margin-top: -4rem;
}

.subtitle-gray {
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 1.1rem;
}

.subtitle-white {
  color: rgba(255, 255, 255, 1);
  font-size: 1.1rem;
}

.btn-time-dashboard {
  color: white;
  background-color: transparent;
  border: none;

  &.active {
    background-color: rgba(46, 185, 255, 0.4);
  }

  &:hover {
    color: white;
    background-color: rgba(46, 185, 255, 0.7);
  }

  &:active {
    background-color: rgba(46, 185, 255, 0.4);
  }
}

.progress {
  border-radius: 2px !important;
  background-color: #384d5a;
  height: 2rem;
}

.progress-bar {
  background-color: #26aae1;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
}

.eta-rebalance {
  font-size: 1rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
}

.info-button {
  z-index: 1000;
  border: none;
  background-color: transparent;
  transition: 0.3s all;

  &:hover {
    transform: translateY(-2px);

    .info-text {
      position: absolute;
      display: block;
      background-color: #323232;
      border: 1px solid #494949;
      color: white;
      font-size: 14px;
      font-weight: lighter;
      padding: 1.5rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 100000;
      width: 250px;
      left: -220px;
      top: 70px;
      pointer-events: none;
      border-radius: 4px;
    }
  }
}

.info-text {
  display: none;
}

.table-alerts > thead > tr > td:first-child {
  width: 10%;
}

.table-alerts > thead > tr > td:nth-child(2) {
  width: 22%;
}

.table-alerts > thead > tr > td:nth-child(4) {
  width: 20%;
}

.alerts-tbody > tr > td:first-child {
  overflow-x: hidden !important;
  width: 10%;
}

.alerts-tbody > tr > td:nth-child(2) {
  overflow-x: hidden !important;
  width: 22%;
}

.alerts-tbody > tr > td:nth-child(4) {
  overflow-x: hidden !important;
  width: 20%;
}

.not-available-traffic {
  font-size: 14px;
  line-height: 15px;
}

.graph-warning {
  color: white;
  display: flex;
  justify-content: center;
}

// .horizontal-date-expand{
//   position: absolute;
//   bottom: -3.5px;
//   left: 80.5px;
//   height: 1px;
//   background-color: rgba(255, 255, 255, 0.2);
//   width: 82%;
//   .sidebar-expand {
//     position: absolute;
//     right: 18px;
//     bottom: 0;
//     height: 98px;
//     background-color: #884242;
//     width: 2px;
//     z-index: 100;
//   }
// }

// .horizontal-date-shrink{
//   position: absolute;
//   bottom: -3.5px;
//   left: 80.5px;
//   height: 1px;
//   background-color: rgba(255, 255, 255, 0.2);
//   width: 79%;
//  .sidebar-shrink {
//     position: absolute;
//     right: 18px;
//     bottom: 0;
//     height: 90px;
//     background-color: #884242;
//     width: 2px;
//     z-index: 100;
//   }
// }

.horizontal-date {
  position: absolute;
  bottom: -3px;
  left: 90.5px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 79%;
}

.hour-date-axis {
  .date-x-axis:nth-child(2),
  .date-x-axis:nth-child(3),
  .date-x-axis:nth-child(5),
  .date-x-axis:nth-child(6),
  .date-x-axis:nth-child(8),
  .date-x-axis:nth-child(9),
  .date-x-axis:nth-child(11),
  .date-x-axis:nth-child(12),
  .date-x-axis:nth-child(14),
  .date-x-axis:nth-child(15),
  .date-x-axis:nth-child(17),
  .date-x-axis:nth-child(18),
  .date-x-axis:nth-child(20),
  .date-x-axis:nth-child(21),
  .date-x-axis:nth-child(23) {
    visibility: hidden;
  }
  // .date-x-axis:last-child {
  //   &::after {
  //     content: "";
  //     position: relative;
  //     bottom: 119px;
  //     right: -11px;
  //     display: block;
  //     height: 90px;
  //     background-color: #885454;
  //     width: 2px;
  //     z-index: 100;
  //   }
  // }
}

.horizontal-date {
  &::before {
    content: "";
    position: fixed;
    right: 210px;
    height: 90px;
    transform: translate(0, -89px);
    background-color: #885454;
    width: 2px;
    z-index: 100;
  }
}

.date-x-axis {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

// .present-daily-line{
//   position: absolute;
//   bottom: -3.5px;
//     right: 227.5px;
//     height: 50%;
//     background-color: #af3f3f;
//     width: 2px;
//     z-index: 100;
// }

@media (min-width: 768px) {
  .info-button {
    &:hover {
      .info-text {
        left: -110px;
        top: 40px;
      }
    }
  }
}

// .mt-10{
//     margin-top: 15rem;
// }

// @-webkit-keyframes glow {
//     0% {
// 		border-color: rgba(48, 170, 225, 0.2);
// 		box-shadow: 0 0 5px rgba(48, 170, 225, 0.2), inset 0 0 5px rgba(48, 170, 225, 0.1), 0 2px 0 #000;
//     }
//     100% {
// 		border-color: rgba(48, 170, 225, 0.2);
// 		box-shadow: 0 0 20px rgba(48, 170, 225, 0.6), inset 0 0 10px rgba(48, 170, 225, 0.4), 0 2px 0 #000;
//     }
// }

// @keyframes glow {
//     0% {
// 		border-color: rgba(48, 170, 225, 0.2);
// 		box-shadow: 0 0 5px rgba(48, 170, 225, 0.2), inset 0 0 5px rgba(48, 170, 225, 0.1), 0 2px 0 #000;
//     }
//     100% {
// 		border-color: rgba(48, 170, 225, 0.2);
// 		box-shadow: 0 0 20px rgba(48, 170, 225, 0.6), inset 0 0 10px rgba(48, 170, 225, 0.4), 0 2px 0 #000;
//     }
// }
