#sidebar {
  .a-item-sidebar {
    color: rgba(255, 255, 255, 0.8);
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;

    &.active {
      .sidebar-item-menu {
        border-right: 4px solid #26aae1;

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
}

#sidebar {
  .container-logo-sidebar {
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }

    .short {
      display: none;
    }
  }

  .title-item-sidebar {
    padding: 0 1.5rem;
    color: #26aae1;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 13px;
  }

  .sidebar-item-menu {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      // background-color: rgba(48, 170, 225, 0.2);
    }
  }

  .zone-container {
    margin-bottom: 3rem;
  }

  .zone-sidebar {
    padding: 0 1.5rem;
    color: #bbb;
    background-color: rgba(48, 170, 225, 0.2);
    border-radius: 4px;
    border: 1px solid #2d5264;
    margin-top: -0.7rem;
  }
}

#sidebar {
  white-space: nowrap;

  &.hide {
    .container-logo-sidebar {
      img {
        height: 30px;
      }

      .expanded {
        display: none;
      }

      .short {
        display: flex;
      }
    }

    .title-item-sidebar {
      // display: none;
      padding: 0;
      white-space: nowrap;
      color: transparent;

      &::before {
        content: "";
        position: absolute;
        width: 50%;
        left: 0;
        right: 0;
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
        height: 5px;
        border-top: 1px solid #bbb;
      }
    }

    .sidebar-item-menu {
      transition: all 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
