@media (min-width: 576px) {

   .kt-login.kt-login--v4 .kt-login__wrapper .kt-login__container {
      width: 430px;
   }
}

@media (min-width: 768px) {
   .kt-container {
      padding: 0 30px;
   }
   .kt-footer {
      .kt-container {
         display: flex;
         justify-content: flex-end;
         align-items: stretch;
         width: inherit;
      }
   }
}

@media (min-width: 1025px) {

   .kt-portlet{
      margin: 0;
   }

   // remove horizontal scroll from main grid
   .kt-grid--root {
      overflow-x: hidden;
   }
   //Hide menus from topbar if sidebar is shown without hiding in hamburguer menu resolutiobn
   .kt-header-menu .kt-menu__nav {
      .header-list-hide-desktop{
         display: none;
      }
   }

   //Sidebar
   #sidebar {
      display: block;
      width: 230px;
      transition: width 0.4s ease;
      overflow-y: hidden;
      position: relative;

      #kt_aside_toggler {
         position: absolute;
         bottom: 0;
         margin-bottom: 40px;
         left: 200px;
         transform: scale(1.2);
      }

      .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text {
         color: #26aae1;
      }

      .kt-aside-menu {
         margin: 0;
      }

      .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav {
         margin-left: 18px;
      }

      &.hide {
         width: 65px;
         transition: width 0.4s ease;
         z-index: 10;

         #kt_aside_toggler {
            position: absolute;
            left: 10px;
            bottom: 0;
            margin-bottom: 40px;
         }

         .kt-aside-menu .kt-menu__nav .kt-menu__item>.kt-menu__submenu {
            display: none;
         }

         .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__submenu .kt-menu__subnav>.kt-menu__item>.kt-menu__link {
            padding: 12px 15px;
         }

         .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__subnav {
            background: linear-gradient(180deg, rgba(250, 250, 250, 0.1) -50%, rgba(32, 32, 32, 0.94) 50%);
            background-color: #232323;
            border-radius: 3px;
            margin: 0;
         }

         .kt-aside-menu .kt-menu__nav .kt-menu__item {
            &:hover {
               .kt-menu__submenu {
                  display: flex;
                  position: fixed;
                  margin-top: 2px;
                  margin-left: 55px;
               }
            }
         }

         .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-icon {
            color: white;
         }

         #cta {
            transform: rotate(0deg);
         }
      }
   }

   #kt_aside_toggler {
      position: absolute;
      z-index: 1000;
      transition: left 0.4s ease;
      background-color: transparent;
      border: none;
      transform: scale(1);
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link {
      padding-right: 29px;
   }

   //    @keyframes sidebarOpen {
   //       0% {
   //           width: 0;
   //       }

   //       100% {
   //           width: 318px;
   //       }
   //   }

   //   @keyframes sidebarClose {
   //    0% {
   //        opacity: 318px;
   //    }

   //    100% {
   //        opacity: 0;
   //    }
   // }

   .kt-aside {
      width: 100%;
      // top: 0;
      min-height: 100%;
      height: auto;
      bottom: 0;
      top: 0;
      // background: linear-gradient(180deg, rgba(30, 112, 154, 0.5) 0%, rgba(2, 2, 2, 0.1) 100%);
      // background-color: #020202;
      // border-right: 2px solid;
      // border-image: linear-gradient(to bottom, #6db9dd, black) 100 100%;
      background-color: rgba(28,28,28,0.94);

      a {
         &:hover {
            color: white;
         }
      }
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item>.kt-menu__link .kt-menu__link-text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.2rem;
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-text {
      color: white;
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__section .kt-menu__section-text {
      color: rgba(255, 255, 255, 1);
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-icon {
      color: rgba(255, 255, 255, 0.7);
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
      color: rgba(255, 255, 255, 0.7);
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__ver-arrow {
      color: rgba(255, 255, 255, 0.7);
   }

   // .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active {
   //     // background: linear-gradient(270deg, rgba(250,250,250,0.1) 0%, rgba(32,32,32, 0.94) 95%);
   //     background: linear-gradient(270deg, #254858 5%, rgba(32, 32, 32, 0.94) 70%);
   // }

   .kt-aside-menu .kt-menu__nav {
      padding: 17px 0;
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-icon,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-text,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__ver-arrow {
      color: white;
   }


   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__link-icon,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__link-text,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__ver-arrow {
      color: #26aae1;
   }

   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-icon,
   .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-ver-arrow {
      color: #26aae1;
   }

   #cta {
      width: 100%;
      cursor: pointer;
      position: absolute;
      transform: rotate(180deg);
      opacity: 0.7;
   }

   .arrow {
      position: absolute;
      width: 12px;
      height: 12px;
      background-size: contain;
      top: -4px;
   }

   .segunda {
      margin-left: 8px;
   }

   .next {
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
   }

   @keyframes bounceAlpha {
      0% {
         opacity: 1;
         transform: translateX(0px) scale(1);
      }

      25% {
         opacity: 0;
         transform: translateX(10px) scale(0.9);
      }

      26% {
         opacity: 0;
         transform: translateX(-10px) scale(0.9);
      }

      55% {
         opacity: 1;
         transform: translateX(0px) scale(1);
      }
   }

   .bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
   }

   .arrow.primera.bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
   }

   .move:hover .arrow {
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
   }

   .move:hover .arrow.primera {
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
   }

   //Header

   .kt-header-menu-wrapper {
      background: transparent;
   }


   .kt-header-menu-mobile .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
      color: white;
   }

   .kt-header-menu-mobile .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text {
      color: white;
   }


   .wrapper-sidebar {
      overflow-y: scroll;
   }

   .content-wrapper {
      overflow-y: hidden;
   }
}
